export const COSTUMER_LANGUAGES = [
  { value: "ita", label: "Italiano", icon: "🇮🇹" },
  { value: "de", label: "Deutsch", icon: "🇩🇪" }
];

export interface Costumer {
  id: string;
  name: string;
  address: string;
  fullAddress: string;
  phone: string;
  phone2: string;
  email: string;
  email2: string;
  email3: string;
  language: string;
  priceVersion: number;
  createdAt: Date;
  updatedAt: Date;
}

export function CreateNewCostumer(override?: Partial<Costumer>): Costumer {
  return {
    id: "",
    name: "",
    address: "",
    fullAddress: "",
    phone: "",
    phone2: "",
    email: "",
    email2: "",
    email3: "",
    language: "",
    priceVersion: 2,
    createdAt: new Date(),
    updatedAt: new Date(),
    ...override
  };
}

export function isValidCostumer(costumer: Costumer): boolean {
  return (
    costumer.name !== "" &&
    costumer.address !== "" &&
    costumer.fullAddress !== "" &&
    costumer.phone !== "" &&
    costumer.email !== "" &&
    costumer.language !== ""
  );
}
