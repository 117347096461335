import supabase from "../../../shared/database/supabase";

interface SoftDeletePaymentRecordByIdRequest{
    id: string;
    deleted_by: string;
    deleted_at: Date;
    deleted: boolean;
}

export interface SoftDeletePaymentRecordResponse {
    data: any[];
  }

export const softDeletePaymentRecordById = async (props: SoftDeletePaymentRecordByIdRequest) => {
    if (!supabase.auth.user()) throw new Error("User is not logged in");

    try {
        const { data, error } = await supabase.from("payment_records").update({
            deleted: true,
            deleted_by: props.deleted_by,
            deleted_at: props.deleted_at
        }).match({ id: props.id });

        if (error) {
            throw new Error(error.message);
        }

        return { data: data };
    } catch (err) {
        console.log(err);
        throw err;
    }
}