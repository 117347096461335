import { Grid, Paper, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { currencyMask, formatIntegerToCurrency } from "../../../utils/helpers/numbers";
import { PaperCard } from "../../../shared/ui/paper-card/paper-card.component";

interface PericiaInsuranceFormProps {
  props: Props;
}

interface Props {
  insuranceHours: number;
  insurancePrice: number;
  updateInsuranceHours: (insuranceHours: number) => void;
}

export const PericiaInsuranceForm: React.FC<PericiaInsuranceFormProps> = ({ props }) => {
  const { insuranceHours, insurancePrice, updateInsuranceHours } = props;
  const [insuranceHoursMask, setInsuranceHoursMask] = useState<string>(insuranceHours.toString());

  const handleInsuranceHoursChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === "") {
      return setInsuranceHoursMask("0");
    }

    const pattern = /^[0-9]*\.?[0-9]{0,2}$/;
    if (!value.match(pattern)) {
      return;
    }

    if (value[0] === "0" && value[1] !== "." && value.length > 1) {
      return setInsuranceHoursMask(value.slice(1));
    }

    setInsuranceHoursMask(value);
  };

  const handleOnBlur = async () => {
    if (insuranceHoursMask === "") {
      return updateInsuranceHours(0);
    }

    updateInsuranceHours(Number(insuranceHoursMask));
  };

  useEffect(() => {
    setInsuranceHoursMask(insuranceHours.toString());
  }, [insuranceHours]);

  return (
    <PaperCard title="Informações do seguro">
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="off"
            fullWidth
            id="insuranceTotalHours"
            label="Total de horas do seguro"
            name="insuranceTotalHours"
            variant="standard"
            onChange={handleInsuranceHoursChange}
            value={insuranceHoursMask}
            onBlur={handleOnBlur}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="off"
            fullWidth
            id="insuranceTotalPrice"
            label="Preço total do seguro/CHF"
            name="insuranceTotalPrice"
            variant="standard"
            //TODO fix this
            value={currencyMask(formatIntegerToCurrency(insurancePrice))}
            disabled
          />
        </Grid>
      </Grid>
    </PaperCard>
  );
};
