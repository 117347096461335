import supabase from "../../../shared/database/supabase";

export interface ListPaymentRecordsResponse {
  PaymentRecords: PaymentRecord[];
}

export interface PaymentRecord {
  id: string;
  userId: string;
  issuedBy: {
    name: string;
    lastName: string;
    displayName: string;
  };
  amount: number;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
  deleted: boolean;
}

export const listPaymentRecords =
  async (): Promise<ListPaymentRecordsResponse> => {
    try {
      const { data, error } = await supabase.functions.invoke(
        "list-payment-records"
      );
      if (error) throw error;

      if (data.error) throw data.error;

      return { PaymentRecords: data || [] };
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
