import FlexContainer from "../../shared/ui/container/flex-container.component";
import { Grid, Paper, Skeleton, TextField, TextareaAutosize, Typography } from "@mui/material";
import { formatCurrency } from "../../utils/helpers/numbers";
import { useListPaymentRecords } from "../../features/payment-record/list-payment-records/useListPaymentRecords";
import { ListPaymentRecordsResponse } from "../../entities/payment-record";
import { ToastContainer, toast } from "react-toastify";
import { useGetUserBalance } from "../../features/user/get-user-balance/api/useGetUserBalance";
import { useGetSalesmanBalance } from "../../features/user/get-user-balance/api/useGetSalesmanBalance";
import supabase from "../../shared/database/supabase";
import { USER_ROLES, UserWithRole } from "../../contexts/user/user.context";

export const GetUserBalancePage = () => {
  const user = supabase.auth.user() as UserWithRole;
  const { data: balanceData, isLoading, isError, refetch: refacthBalance } = useGetUserBalance();
  const {
    data: salesmanBalanceData,
    isLoading: isLoadingSalesman,
    isError: isErrorSalesman,
    refetch: refetchSalesmanBalance
  } = useGetSalesmanBalance();

  const {
    data: paymentRecords,
    isError: isErrorListPaymentRecords,
    isLoading: isLoadingListPaymentRecords,
    refetch: refetchPaymentRecords
  } = useListPaymentRecords();

  const IS_ALLOWED = (...roles: string[]) => roles.includes(user.permission);

  if (isError) {
    toast.error("Erro ao buscar saldo do usuário");
  }

  if (isErrorSalesman) {
    toast.error("Erro ao buscar saldo do usuário");
  }

  if (isErrorListPaymentRecords) {
    toast.error("Erro ao buscar histórico de pagamentos");
  }

  return (
    <FlexContainer>
      <Typography variant="h4" component="h1" mb={2}>
        Saldo {balanceData && `- ${balanceData?.userDisplayName || "Usuário"}`}
      </Typography>
      {IS_ALLOWED(USER_ROLES.ADMIN, USER_ROLES.LIMITED_ADMIN, USER_ROLES.USER, USER_ROLES.USER_PRIVILEGED, USER_ROLES.SALESMAN) &&
        wrapComponentInSkeleton(
          <Paper elevation={3} sx={{ padding: 5, margin: 2, width: "100%", borderRadius: "8px" }}>
            <Typography variant="h5" component="h2" mb={2}>
              Balanço
            </Typography>
            <Grid container>
              <Grid item xs={12} md={4}>
                <TextField
                  size="medium"
                  margin="dense"
                  id="name"
                  label="Histórico feito"
                  variant="outlined"
                  value={formatCurrency(balanceData?.totalHistory || 0)}
                  name="balanceData"
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="medium"
                  margin="dense"
                  id="name"
                  label="Histórico recebido"
                  variant="outlined"
                  value={formatCurrency(balanceData?.totalReceived || 0)}
                  name="balanceData"
                  disabled
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  size="medium"
                  margin="dense"
                  id="name"
                  label="A receber"
                  variant="outlined"
                  value={formatCurrency(balanceData?.balance || 0)}
                  name="balanceData"
                  disabled
                />
              </Grid>
            </Grid>
          </Paper>,
          isLoading
        )}
      {IS_ALLOWED(USER_ROLES.ADMIN, USER_ROLES.LIMITED_ADMIN, USER_ROLES.USER, USER_ROLES.USER_PRIVILEGED, USER_ROLES.SALESMAN) &&
        wrapComponentInSkeleton(
          <Paper elevation={3} sx={{ padding: 5, margin: 2, width: "100%", borderRadius: "8px" }}>
            <Typography variant="h5" component="h2" mb={2}>
              Histórico recente de pagamentos
            </Typography>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Histórico de pagamentos..."
              value={formatPaymentRecordStatements(paymentRecords)}
              style={{ width: "100%" }}
              disabled
            />
          </Paper>,
          isLoading
        )}
      <ToastContainer />
    </FlexContainer>
  );
};

function wrapComponentInSkeleton(component: JSX.Element, isLoading: boolean) {
  if (!isLoading) return component;

  return <Skeleton width="100%">{component}</Skeleton>;
}

function formatPaymentRecordStatements(paymentRecords: ListPaymentRecordsResponse | undefined | null) {
  if (!paymentRecords) return "";

  return paymentRecords.PaymentRecords
  .filter((payment) => !payment.deleted)
  .map(
    (payment) =>
      `\n- ${payment.issuedBy.displayName} pagou ${formatCurrency(payment.amount)} em ${new Date(payment.createdAt).toLocaleDateString(
        "pt-BR"
      )} - Detalhes: ${payment.description}.`
  ).join("\n\n");
}
