import { useParams } from "react-router-dom";
import FlexContainer from "../../shared/ui/container/flex-container.component";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  TextField,
  TextareaAutosize,
  Typography
} from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { currencyMask, formatCurrency, getNumberFromCurrencyMask } from "../../utils/helpers/numbers";
import { LoadingButton } from "@mui/lab";
import { useCreatePaymentRecord } from "../../features/payment-record/create-payment-record/useCreatePaymentRecord";
import { useEffect, useRef, useState } from "react";
import { ListPaymentRecordsResponse } from "../../entities/payment-record";
import { ToastContainer, toast } from "react-toastify";
import { useGetUserBalanceById } from "../../features/user/get-user-balance-by-id/api/useGetUserBalanceById";
import { useListPaymentRecordsByUserId } from "../../features/payment-record/list-payment-records-by-user-id/useListPaymentRecordsByUserId";
import { SoftDeletePaymentProps, useSoftDeletePaymentRecordById } from "../../features/payment-record/soft-delete-payment-record/useSoftDeletePaymentRecord";
import { PaymentRecord } from "../../entities/payment-record/api/list-payment-records";
import { USER_ROLES, UserWithRole } from "../../contexts/user/user.context";
import supabase from "../../shared/database/supabase";

const INITIAL_PAYMENT_RECORD_STATE = {
  amount: 0,
  description: "",
  userId: ""
};

const INITIAL_DELETE_PAYMENT_RECORD_STATE = {
  paymentId: "",
  deletedBy: ""
};

export const GetUserBalanceByIdPage = () => {
  const user = supabase.auth.user() as UserWithRole;
  const { userId } = useParams();
  const [description, setDescription] = useState("");
  const [amountMask, setAmountMask] = useState<string>(currencyMask("0"));
  const [paymentRecord, setPaymentRecord] = useState(INITIAL_PAYMENT_RECORD_STATE);
  const [selectedPaymentRecordToDelete, setSelectedPaymentRecordToDelete] = useState<PaymentRecord | null>(null);
  const [softDeletePaymentProps, setSoftDeletePaymentProps] = useState<SoftDeletePaymentProps>(INITIAL_DELETE_PAYMENT_RECORD_STATE);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const deleteRef = useRef<HTMLInputElement | null>(null);
  const { data: paymentDeleted, isLoading: isLoadingSoftDeletePayment, isError: isErrorSoftDeletePayment } = useSoftDeletePaymentRecordById(softDeletePaymentProps);
  const { data: balanceData, isLoading, isError, refetch: refacthBalance } = useGetUserBalanceById(userId || "");
  const { data: paymentCreated, isLoading: isLoadingCreatePayment, isError: isErrorCreatePayment } = useCreatePaymentRecord(paymentRecord);
  const {
    data: paymentRecords,
    isError: isErrorListPaymentRecords,
    isLoading: isLoadingListPaymentRecords,
    refetch: refetchPaymentRecords
  } = useListPaymentRecordsByUserId(userId || "");


  const IS_ALLOWED = (...roles: string[]) => roles.includes(user.permission);

  const handleCreateNewPayment = () => {
    if (!userId) return;
    const value = getNumberFromCurrencyMask(amountMask);

    if (value <= 0) {
      toast.warn("O valor do pagamento deve ser maior que zero");
      return;
    }

    setPaymentRecord({
      ...paymentRecord,
      userId,
      description,
      amount: value
    });
  };

  useEffect(() => {
    if (!paymentCreated) return;

    setPaymentRecord(INITIAL_PAYMENT_RECORD_STATE);
    setAmountMask(currencyMask("0"));
    setDescription("");
    refacthBalance();
    refetchPaymentRecords();
    toast.success("Pagamento emitido com sucesso");
  }, [paymentCreated]);

  useEffect(() => {
    if (!paymentDeleted) return;
    setSelectedPaymentRecordToDelete(null);
    refetchPaymentRecords();
    refacthBalance();
    toast.success("Pagamento removido com sucesso");
  }, [paymentDeleted]);

  if (isError) {
    toast.error("Erro ao buscar saldo do usuário");
  }

  if (isErrorCreatePayment) {
    toast.error("Erro ao emitir pagamento");
  }

  if (isErrorListPaymentRecords) {
    toast.error("Erro ao buscar histórico de pagamentos");
  }

  const handleClickOpenToDelete = (paymentRecord: PaymentRecord) => {
    setSelectedPaymentRecordToDelete(paymentRecord);
    setOpenDeleteDialog(true);
  };

  const handleClickCloseToDelete = () => {
    setSelectedPaymentRecordToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleClickConfirmToDelete = () => {
    if (!selectedPaymentRecordToDelete) return;
    if (!user || !user.id) return;

    if (deleteRef?.current?.value.toLocaleLowerCase() !== "remover") {
      return alert("Digite remover para confirmar");
    }

    setSoftDeletePaymentProps({
      paymentId: selectedPaymentRecordToDelete.id,
      deletedBy: user.id
    });
    setOpenDeleteDialog(false);
  };


  return (
    <FlexContainer>
      <Typography variant="h4" component="h1" mb={2}>
        Saldo {balanceData && `- ${balanceData?.userDisplayName || "Usuário"}`}
      </Typography>
      {wrapComponentInSkeleton(
        <Paper elevation={3} sx={{ padding: 5, margin: 2, width: "100%", borderRadius: "8px" }}>
          <Typography variant="h5" component="h2" mb={2}>
            Balanço
          </Typography>
          <Grid container>
            <Grid item xs={12} md={4}>
              <TextField
                size="medium"
                margin="dense"
                id="name"
                label="Histórico feito"
                variant="outlined"
                value={formatCurrency(balanceData?.totalHistory || 0)}
                name="balanceData"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="medium"
                margin="dense"
                id="name"
                label="Histórico recebido"
                variant="outlined"
                value={formatCurrency(balanceData?.totalReceived || 0)}
                name="balanceData"
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                size="medium"
                margin="dense"
                id="name"
                label="A receber"
                variant="outlined"
                value={formatCurrency(balanceData?.balance || 0)}
                name="balanceData"
                disabled
              />
            </Grid>
          </Grid>
        </Paper>,
        isLoading
      )}
      {wrapComponentInSkeleton(
        <Paper elevation={3} sx={{ padding: 5, margin: 2, width: "100%", borderRadius: "8px" }}>
          <Typography variant="h5" component="h2" mb={2}>
            Emitir Pagamento
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                size="medium"
                margin="dense"
                id="name"
                label="Valor"
                variant="standard"
                value={amountMask}
                name="amount"
                onChange={(e) => setAmountMask(currencyMask(e.target.value))}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                size="medium"
                margin="dense"
                id="name"
                label="Descrição"
                variant="standard"
                value={description}
                name="description"
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <LoadingButton fullWidth variant="contained" onClick={handleCreateNewPayment} loading={isLoadingCreatePayment}>
                Emitir Pagamento
              </LoadingButton>
            </Grid>
          </Grid>
        </Paper>,
        isLoading
      )}
      {wrapComponentInSkeleton(
        <Paper elevation={3} sx={{ padding: 5, margin: 2, width: "100%", borderRadius: "8px" }}>
          <Typography variant="h5" component="h2" mb={2}>
            Histórico recente de pagamentos
          </Typography>
          <List>
            {paymentRecords?.PaymentRecords.map((payment) => {
              if (payment.deleted) return null;
              return (
                <ListItem
                  key={payment.id}
                  role={undefined}
                  dense
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "center"
                  }}
                >
                  <ListItemText id={payment.id} primary={payment.issuedBy.displayName} sx={{ flex: "1" }} />
                  <ListItemText
                    id={payment.id}
                    primary={new Date(payment.createdAt).toLocaleDateString("pt-BR")}
                    sx={{ flex: "1", textAlign: "center" }}
                  />
                  <ListItemText
                    id={payment.id}
                    primary={formatCurrency(payment.amount)}
                    sx={{ flex: "1", textAlign: "center" }}
                  />
                  <ListItemText
                    id={payment.id}
                    primary={payment.description}
                    sx={{ flex: "1", textAlign: "center" }}
                  />
                  {IS_ALLOWED(USER_ROLES.ADMIN) && (
                    <IconButton color="error" onClick={() => handleClickOpenToDelete(payment)}>
                      <DeleteForeverIcon />
                    </IconButton>
                  )}
                </ListItem>
              );
            })}
          </List>
        </Paper>,
        isLoading
      )}
      <ToastContainer />
      <Dialog open={openDeleteDialog} onClose={handleClickCloseToDelete}>
        <DialogTitle>Remover pagamento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza que deseja remover este pagamento? Digite <strong>remover</strong> no campo abaixo para confirmar.
          </DialogContentText>
          <TextField autoFocus margin="dense" id="name" label="remover" type="text" fullWidth variant="standard" inputRef={deleteRef} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseToDelete}>Cancelar</Button>
          <Button onClick={handleClickConfirmToDelete} color="error">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </FlexContainer>
  );
};

function wrapComponentInSkeleton(component: JSX.Element, isLoading: boolean) {
  if (!isLoading) return component;

  return <Skeleton width="100%">{component}</Skeleton>;
}

function formatPaymentRecordStatements(paymentRecords: ListPaymentRecordsResponse | undefined | null) {
  if (!paymentRecords) return "";

  return paymentRecords.PaymentRecords.map(
    (payment) =>
      `\n- ${payment.issuedBy.displayName} pagou ${formatCurrency(payment.amount)} em ${new Date(payment.createdAt).toLocaleDateString(
        "pt-BR"
      )} - Detalhes: ${payment.description}.`
  ).join("\n\n");
}
