import { UseQueryResult, useQuery } from "react-query";
import {
  SoftDeletePaymentRecordResponse,
  softDeletePaymentRecordById,
} from "../../../entities/payment-record/api/soft-delete-payment-record";

export interface SoftDeletePaymentProps {
  paymentId: string;
  deletedBy: string;
}

export const queryKeys = {
  softDeletePaymentRecord: (props: SoftDeletePaymentProps) => [
    "softDeletePaymentRecord",
    props,
  ],
};

export function useSoftDeletePaymentRecordById(
  props: SoftDeletePaymentProps
): UseQueryResult<SoftDeletePaymentRecordResponse, Error> {
  return useQuery(
    queryKeys.softDeletePaymentRecord(props),
    () =>
      softDeletePaymentRecordById({
        id: props.paymentId,
        deleted_by: props.deletedBy,
        deleted_at: new Date(),
        deleted: true,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: props.paymentId !== "" && props.deletedBy!=="",
    }
  );
}