import { Grid, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Stack from "@mui/material/Stack";
import {startOfDay, endOfDay} from "../../../shared/helpers/date";

interface DateRangeProps {
  props: Props;
}

interface Props {
  startDate: Date;
  endDate: Date;
  onChange: (startDate: Date, endDate: Date) => void;
}

const DateRange: React.FC<DateRangeProps> = ({ props }) => {
  const { startDate, endDate, onChange } = props;

  const handleDateChange = (startDate: Date, endDate: Date) => {
    onChange(startDate, endDate);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DatePicker
              label="Inicio"
              openTo="day"
              inputFormat="DD/MM/YYYY"
              shouldDisableDate={(date) => date > endDate}
              views={["year", "month", "day"]}
              value={startDate}
              onChange={(newValue: any) => {
                if (newValue) handleDateChange(startOfDay(new Date(newValue)), endDate);
              }}
              renderInput={(params: any) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Stack>
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Stack spacing={3}>
            <DatePicker
              label="Limite"
              openTo="day"
              inputFormat="DD/MM/YYYY"
              disableFuture
              views={["year", "month", "day"]}
              value={endDate}
              onChange={(newValue: any) => {
                if (newValue) handleDateChange(startDate, endOfDay(new Date(newValue)));
              }}
              renderInput={(params: any) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Stack>
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
};

export default DateRange;
