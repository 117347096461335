import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import { object, string } from "yup";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Container,
  Grid,
  Paper,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { FormTextField } from "../../../components/form/form-input/form-input.component";
import { createCostumer } from "../../../storage/supabase/costumer/costumer.storage";
import { CostumerLanguagesSelect } from "../../../entities/costumer/ui/costumar-lang-select";
import { ReactNode } from "react";

let costumerSchema = object({
  name: string().required("Nome é obrigatório"),
  address: string()
    .url("Este link é inválido"),
  phone2: string().nullable(),
  email: string().email("Este email não é válido").nullable(),
});

interface FormValues {
  name: string;
  address: string;
  fullAddress: string;
  phone: string;
  phone2: string;
  email: string;
  email2: string;
  email3: string;
  language: string;
}

const initialValues: FormValues = {
  name: "",
  address: "",
  fullAddress: "",
  phone: "",
  phone2: "",
  email: "",
  email2: "",
  email3: "",
  language: "ita",
};

const AddCostumer = () => {
  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          minHeight: "72vh",
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography component="h1" variant="h4">
          Adicionar cliente
        </Typography>
        <Paper
          elevation={3}
          sx={{
            marginTop: 4,
            padding: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={costumerSchema}
            onSubmit={(
              values: FormValues,
              formikHelpers: FormikHelpers<FormValues>
            ) => {
              const formkiCreateCostumer = async () => {
                try {
                  const res = await createCostumer(values);

                  if (res?.error) {
                    formikHelpers.setSubmitting(false);
                    return toast.error(res.error.message);
                  }

                  formikHelpers.setSubmitting(false);
                  toast.success("Cliente adicionado com sucesso!");
                  formikHelpers.resetForm();
                } catch (err) {
                  toast.error("Erro ao adicionar cliente");
                  formikHelpers.setSubmitting(false);
                }
              };
              formkiCreateCostumer();
            }}
          >
            {(formikProps: FormikProps<FormValues>) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Field
                      name="name"
                      label="Cliente"
                      size="small"
                      required
                      fullWidth
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      fullWidth
                      name="address"
                      label="Endereço (Google Maps link)"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      fullWidth
                      name="fullAddress"
                      label="Endereço"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Field
                      fullWidth
                      name="email"
                      label="Email"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Field
                      fullWidth
                      name="email2"
                      label="Email 2"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Field
                      fullWidth
                      name="email3"
                      label="Email 3"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      fullWidth
                      name="phone"
                      label="Phone"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field
                      fullWidth
                      name="phone2"
                      label="Phone2"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12} md={4} mt={2}>
                    <CostumerLanguagesSelect
                      language={formikProps.values.language}
                      handleLanguageChange={(
                        event: SelectChangeEvent<string>,
                        child: ReactNode
                      ) => {
                        formikProps.setFieldValue(
                          "language",
                          event.target.value as string
                        );
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} mt={3}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      loading={formikProps.isSubmitting}
                      disabled={formikProps.isSubmitting}
                    >
                      Adicionar Cliente
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default AddCostumer;
