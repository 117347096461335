import { Costumer } from "../../costumer/model";

export const createNewInvoice = (): Invoice => ({
  id: "",
  costumer: {
    id: "",
    name: "",
    address: "",
    fullAddress: "",
    language: "",
    phone: "",
    phone2: "",
    email: "",
    email2: "",
    email3: "",
    priceVersion: 2,
    createdAt: new Date(),
    updatedAt: new Date()
  },
  totalPrice: 0,
  totalPericias: 0,
  ivaPercentage: 0,
  invoiceText: "",
  payed: false,
  payedAt: new Date(),
  payedValue: 0,
  startDate: new Date(),
  endDate: new Date(),
  deleted: false,
  createdAt: new Date(),
  updatedAt: new Date(),
  invoiceCount: 0,
  invoiceCustomCount: 0
});

export interface Invoice {
  id: string;
  costumer: Costumer;
  totalPrice: number;
  totalPericias: number;
  ivaPercentage: number;
  invoiceText: string;
  payed: boolean;
  payedAt: Date;
  payedValue: number;
  startDate: Date;
  endDate: Date;
  deleted: boolean;
  createdAt: Date;
  updatedAt: Date;
  invoiceCount: number;
  invoiceCustomCount: number;
}
