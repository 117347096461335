import { Formik, FormikHelpers, FormikProps, Form, Field } from "formik";
import { object, string } from "yup";
import { LoadingButton } from "@mui/lab";
import { Box, Container, Grid, Paper, SelectChangeEvent, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { FormTextField } from "../../../components/form/form-input/form-input.component";
import { ReactNode, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCostumerById, upsertCostumer } from "../../../storage/supabase/costumer/costumer.storage";
import { CostumerLanguagesSelect } from "../../../entities/costumer/ui/costumar-lang-select";
import UserAutocompleteSearchBar from "../../../entities/user/ui/users-autocomplete-search-bar.component";
import { User } from "../../../entities/user";
import { USER_ROLES, UserWithRole } from "../../../contexts/user/user.context";
import supabase from "../../../shared/database/supabase";
import { useAddSalesman } from "../../../features/costumer/add-salesman/api/useAddSalesman";

let costumerSchema = object({
  name: string().required("Nome é obrigatório"),
  address: string().url("Este link é inválido"),
  fullAddress: string(),
  phone: string(),
  phone2: string().nullable(),
  email: string().email("Este email não é válido").nullable()
});

interface FormValues {
  id: string;
  name: string;
  address: string;
  fullAddress: string;
  phone: string;
  phone2: string;
  email: string;
  email2: string;
  email3: string;
  language: string;
}

const initialValues: FormValues = {
  id: "",
  name: "",
  address: "",
  fullAddress: "",
  phone: "",
  phone2: "",
  email: "",
  email2: "",
  email3: "",
  language: ""
};

const INITIAL_USER: User = {
  id: "",
  name: "",
  displayName: "",
  lastName: "",
  permissions: []
};

const EditCostumer = () => {
  const u = supabase.auth.user() as UserWithRole;
  const { costumerID } = useParams();
  const [costumer, setCostumer] = useState(initialValues);
  const [user, setUser] = useState<User>(INITIAL_USER);
  const [addSalemanProps, setAddSalesmanProps] = useState({ userId: user.id, costumerId: costumerID || "", enabled: false });
  const { data, error, isLoading } = useAddSalesman(addSalemanProps);

  const IS_ALLOWED = (...roles: string[]) => roles.includes(u.permission);

  const handleAddSalesman = () => {
    setAddSalesmanProps({ userId: user.id, costumerId: costumerID || "", enabled: true });
  };

  if (data) {
    toast.success("Vendedor adicionado com sucesso!");
    setUser({ ...INITIAL_USER });
    setAddSalesmanProps({ userId: user.id, costumerId: costumerID || "", enabled: false });
  }

  if (error) {
    setUser({ ...INITIAL_USER });
    toast.error("Erro ao adicionar vendedor!");
    setAddSalesmanProps({ userId: user.id, costumerId: costumerID || "", enabled: false });
  }

  useEffect(() => {
    if (!costumerID) return;

    const fetchCostumer = async () => {
      const res = await getCostumerById(costumerID);

      if (res.error) {
        console.log(res.error);
        toast.error("Erro ao buscar cliente!");
        return;
      }

      const { id, name, address = "", full_address: fullAddress = "", phone = "", phone2 = "", email = "", email2 = "", email3 = "", language = "" } = res.data;
      setCostumer({
        id,
        name,
        address,
        fullAddress,
        phone,
        phone2,
        email,
        email2,
        email3,
        language
      });
    };
    fetchCostumer();
  }, []);

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    setAddSalesmanProps({ userId: user.id, costumerId: costumerID || "", enabled: false });
  }, [user, costumerID]);

  return (
    <Container component="main" maxWidth="md">
      <Box
        sx={{
          minHeight: "72vh",
          marginTop: 8,
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Typography component="h1" variant="h4" mb={5}>
          Perfil do Cliente
        </Typography>
        <Paper
          elevation={3}
          sx={{
            padding: 4,
            borderRadius: "8px"
          }}
        >
          <Formik
            initialValues={costumer}
            enableReinitialize
            validationSchema={costumerSchema}
            onSubmit={(values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
              const formkiUpdateCostumer = async () => {
                try {
                  await upsertCostumer(values);
                  formikHelpers.setSubmitting(false);
                  toast.success("Cliente atualizado com sucesso!");
                } catch (err) {
                  toast.error("Erro ao atualizar cliente");
                }
              };
              formkiUpdateCostumer();
            }}
          >
            {(formikProps: FormikProps<FormValues>) => (
              <Form noValidate autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Field value={costumer.name} name="name" label="Cliente" size="small" required fullWidth component={FormTextField} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      initialValue={costumer.address}
                      fullWidth
                      name="address"
                      label="Endereço (Google Maps link)"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Field
                      initialValue={costumer.address}
                      fullWidth
                      name="fullAddress"
                      label="Endereço"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Field initialValue={costumer.email} fullWidth name="email" label="Email" size="small" component={FormTextField} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Field initialValue={costumer.email2} fullWidth name="email2" label="Email 2" size="small" component={FormTextField} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Field initialValue={costumer.email3} fullWidth name="email3" label="Email 3" size="small" component={FormTextField} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Field
                      initialValue={costumer.phone}
                      fullWidth
                      name="phone"
                      label="Phone"
                      size="small"
                      component={FormTextField}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Field initialValue={costumer.phone2} fullWidth name="phone2" label="Phone2" size="small" component={FormTextField} />
                  </Grid>
                  <Grid item xs={12} md={4} mt={2}>
                    <CostumerLanguagesSelect
                      language={formikProps.values.language}
                      handleLanguageChange={(event: SelectChangeEvent<string>, child: ReactNode) => {
                        formikProps.setFieldValue("language", event.target.value as string);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} mt={3}>
                    <LoadingButton
                      type="submit"
                      fullWidth
                      variant="contained"
                      loading={formikProps.isSubmitting}
                      disabled={formikProps.isSubmitting}
                    >
                      Atualizar Cliente
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
        {IS_ALLOWED(USER_ROLES.ADMIN) && (
          <Paper
            elevation={3}
            sx={{
              padding: 4,
              borderRadius: "8px",
              marginTop: 4
            }}
          >
            <Typography component="h1" variant="h5" mb={3}>
              Adicionar vendedor
            </Typography>
            <UserAutocompleteSearchBar props={{ user, updateUser: setUser }} />
            <LoadingButton
              type="submit"
              fullWidth
              variant="contained"
              sx={{ marginTop: "2rem" }}
              onClick={handleAddSalesman}
              disabled={addSalemanProps.userId.length === 0}
              loading={isLoading}
            >
              Adicionar vendendor
            </LoadingButton>
          </Paper>
        )}
      </Box>
      <ToastContainer />
    </Container>
  );
};

export default EditCostumer;
